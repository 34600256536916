<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        type="pagetop"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        setHeight="500px"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            dense
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fire-resistant-struct-spec-tab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      tab: 'fireResistantStructSpecInfo',
      tabItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        // 기본정보 정보
        { name: 'fireResistantStructSpecInfo', icon: 'info', label: 'LBL0010488', component: () => import(`${'./fireResistantStructSpecInfo.vue'}`) },  // 내화구조 명세
        // 도면
        { name: 'fireResistantStructSpecPid', icon: 'tab', label: 'LBL0010489', component: () => import(`${'./fireResistantStructSpecPid.vue'}`) }, // 내화구조도
        // 문서
        { name: 'fireResistantStructSpecDoc', icon: 'assignment', label: 'LBL0010490', component: () => import(`${'./fireResistantStructSpecDoc.vue'}`) } // 내화구조 명세서
      ]
    },

  }
};
</script>